import * as React from "react";
import Layout from "../../layouts";
import Button from "@partials/button";
import ImageBlock from "@partials/image-block/image-block";
import "./_gallery_styles.scss";
import QuoteSection from "@partials/quote-section/quote-section";
import { quotes, positions } from "../../../pageData";

import ApplesOfNadorp from "@images/galleries/series-I/10-DonaldEvans_ApplesofNadorp.png";
import HeddaSterneSigns from "@images/galleries/series-I/10-HeddaSterne_Signs.png";
import JohnAshbery_L_Heure from "@images/galleries/series-I/10-JohnAshbery_L_HeureExquise.png";
import MarryFrankCooperLake from "@images/galleries/series-I/10-MaryFrank_CooperLake.png";
import MichaelLangenstein_SwimmerofLiberty from "@images/galleries/series-I/10-MichaelLangenstein_SwimmerofLiberty.png";
import LarryWilliams_SomePopularDesire from "@images/galleries/series-I/10-LarryWilliams_Somepopulardesiresand_orfantasies.png";
import RobertMotherwell_GauloisCollage from "@images/galleries/series-I/10-RobertMotherwell_GauloisCollage.png";
import RobertWilson_Speech from "@images/galleries/series-I/10-RobertWilson_Speechfrom_LetterforQueenVictoria_.png";
import RussellDrisch_untitled from "@images/galleries/series-I/10-RussellDrisch_untitledelement.png";
import VictoriaBarr_KulaBeach from "@images/galleries/series-I/10-VictoriaBarr_KulaBeachBali1977.png";
import AndrewPMacnair_BalloonFrame from "@images/galleries/series-I/AndrewPMacnair_BalloonFrame.png";
import ChristopherHewat_FromtheMelonCollection from "@images/galleries/series-I/ChristopherHewat_FromtheMelonCollection.png";
import ClausHole_GenusBos from "@images/galleries/series-I/ClausHole_GenusBos.png";
import DonaldBarthelme_HenryJamesChief from "@images/galleries/series-I/DonaldBarthelme_HenryJamesChief.png";
import DonaldWindham_Pier from "@images/galleries/series-I/DonaldWindham_PierProvincetownMass.png";
import EdwardGorey_Untitled from "@images/galleries/series-I/EdwardGorey_Untitled.png";
import GonzaloFonseca_UrnaParcarum from "@images/galleries/series-I/GonzaloFonseca_UrnaParcarum.png";
import HelenMirandaWilson_July from "@images/galleries/series-I/HelenMirandaWilson_July.png";
import HitchLyman_TheTempleofZeusAthens from "@images/galleries/series-I/HitchLyman_TheTempleofZeusAthens.png";
import JamesSRossant_TheBrownorWesternGateontheRiverBrue from "@images/galleries/series-I/JamesSRossant_TheBrownorWesternGateontheRiverBrue.png";
import JamesStevensonMorning from "@images/galleries/series-I/JamesStevensonMorning.png";
import JanetStayton_GreenCupLaVita from "@images/galleries/series-I/JanetStayton_GreenCup-LaVita.png";
import JimDine_SelfPortrait1977 from "@images/galleries/series-I/JimDine_SelfPortrait1977.png";
import JoeBrainard_YellowButterfly from "@images/galleries/series-I/JoeBrainard_YellowButterfly.png";
import JohnCage_Forty_fiveDrawingsbyThoreau from "@images/galleries/series-I/JohnCage_Forty-fiveDrawingsbyThoreau.png";
import JohnMorning_FourbySixThreebyEight from "@images/galleries/series-I/JohnMorning_FourbySixThreebyEight.png";
import LeeKrasner_WildlifeinSouthwesternDesert from "@images/galleries/series-I/LeeKrasner_WildlifeinSouthwesternDesert.png";
import LenoreTawney_Untitled from "@images/galleries/series-I/LenoreTawney_Untitled.png";
import MarilynHamann_Untitled from "@images/galleries/series-I/MarilynHamann_Untitled.png";
import MaureenMcCabe_ALadyoftheNightLeadingtheBull from "@images/galleries/series-I/MaureenMcCabe_ALadyoftheNightLeadingtheBull.png";
import MichaelGraves_PreandPostCard from "@images/galleries/series-I/MichaelGraves_PreandPostCard.png";
import PaulLinfante_Ricordi1977 from "@images/galleries/series-I/PaulLinfante_Ricordi1977.png";
import RichardBenson_TheChateauatMaintenon from "@images/galleries/series-I/RichardBenson_TheChateauatMaintenon.png";
import RobertMKulicke_StillLifeFruit from "@images/galleries/series-I/RobertMKulicke_StillLifeFruit.png";
import RobertMorrisPentagon from "@images/galleries/series-I/RobertMorrisPentagon.png";
import RobertReid_Figureonthebeach from "@images/galleries/series-I/RobertReid_Figureonthebeach.png";
import SaulSteinberg_EastSideSunset from "@images/galleries/series-I/SaulSteinberg_EastSideSunset.png";

import michael_langenstein_two_in_time from "@images/galleries/series-I/michael_langenstein_two_in_time.png";
import two_in_time from '../../video/two_in_time.gif'
import two_in_time_video from '../../video/two_in_time.mp4'

export default function SeriesOne() {
    return (
        <Layout pageTitle="Series I">
            <section className="gallery-hero-section position-relative overflow-hidden">
                <div className="container">
                    <div className="row c-mb-only-8">
                        <div className="col-lg-7">
                            <h1 className="c-mb-only-2">Artists’ Postcards SERIES I</h1>
                        </div>
                        <div className="col-lg-6">
                            <p className="c-mb-only-3">
                                The initial edition of thirty-nine postcards was the first of its kind to be published in the U.S.A. Distinct from art postcards that are miniature versions of celebrated works, each Artists’ Postcard carries a new picture created especially for it by a living artist.
                            </p>
                            <p className="c-mb-only-3">
                                The artists represented in this first edition, originally published in 1977, includes poets, photographers, architects, and a composer, as well as painters and sculptors.
                            </p>
                        </div>
                        <div className="col-lg-6">
                            <p className="c-mb-only-3">
                                The original 4 X 6 inch works created for Artists’ Postcards opened in an exhibition at The Drawing Center in New York, City and traveled to museums throughout the United States during 1978 and 1979 in an exhibition developed by the Smithsonian Institution Traveling Exhibition Service.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-lg-7 offset-lg-1">
                            <ImageBlock
                                imageCustomClassName="border border-2"
                                author="Donald Evans"
                                image={ApplesOfNadorp}
                                imageX2={ApplesOfNadorp}
                                caption="Apples of Nadorp"
                                alt="Apples of Nadorp"
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-4">
                            <ImageBlock
                                imageCustomClassName="border border-2"
                                author="Hedda Sterne"
                                image={HeddaSterneSigns}
                                imageX2={HeddaSterneSigns}
                                caption="Signs"
                                alt="Signs"
                                signaturePosition={positions.BOTTOM_LEFT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-1">
                            <ImageBlock
                                author="John Ashbery"
                                image={JohnAshbery_L_Heure}
                                imageX2={JohnAshbery_L_Heure}
                                caption="Heure Exquise"
                                alt="Heure Exquise"
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-4">
                            <ImageBlock
                                author="Mary Frank"
                                image={MarryFrankCooperLake}
                                imageX2={MarryFrankCooperLake}
                                caption="Cooper Lake, N.Y"
                                alt="Cooper Lake, N.Y"
                                signaturePosition={positions.BOTTOM_LEFT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-1">
                            <ImageBlock
                                author="Michael Langenstein"
                                image={MichaelLangenstein_SwimmerofLiberty}
                                imageX2={MichaelLangenstein_SwimmerofLiberty}
                                caption="Swimmer of Liberty"
                                alt="Swimmer of Liberty"
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 offset-lg-5">
                            <ImageBlock
                                author="Robert Motherwell"
                                image={RobertMotherwell_GauloisCollage}
                                imageX2={RobertMotherwell_GauloisCollage}
                                caption="Gaulois Collage"
                                alt="Gaulois Collage"
                                signaturePosition={positions.BOTTOM_LEFT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-1">
                            <ImageBlock
                                imageCustomClassName="border border-2"
                                author="Robert Wilson"
                                image={RobertWilson_Speech}
                                imageX2={RobertWilson_Speech}
                                caption="Speech from Letter for Queen Victoria"
                                alt="Speech from Letter for Queen Victoria"
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-5">
                            <ImageBlock
                                author="Russell Drisch"
                                image={RussellDrisch_untitled}
                                imageX2={RussellDrisch_untitled}
                                caption=""
                                alt="Swimming pool with old pool deck chair"
                                signaturePosition={positions.BOTTOM_LEFT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-1">
                            <ImageBlock
                                author="Larry Williams"
                                image={LarryWilliams_SomePopularDesire}
                                imageX2={LarryWilliams_SomePopularDesire}
                                caption="Some popular desires and or fantasies"
                                alt="Some popular desires and or fantasies"
                                signaturePosition={positions.BOTTOM_LEFT}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <QuoteSection data={quotes.galleryQuoteI} mt0/>
            <section className="position-relative overflow-hidden">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 offset-lg-1">
                            <ImageBlock
                                imageCustomClassName="border border-2"
                                author="Victoria Barr"
                                image={VictoriaBarr_KulaBeach}
                                imageX2={VictoriaBarr_KulaBeach}
                                alt="Kula Beach, Bali 1977"
                                caption="Kula Beach, Bali 1977"
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-5 offset-lg-6">
                            <ImageBlock
                                author="Andrew P. Macnair"
                                image={AndrewPMacnair_BalloonFrame}
                                imageX2={AndrewPMacnair_BalloonFrame}
                                caption="Balloon Frame"
                                alt="Balloon Frame"
                                signaturePosition={positions.BOTTOM_LEFT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-1">
                            <ImageBlock
                                imageCustomClassName="border border-2"
                                author="Christopher Hewat"
                                image={ChristopherHewat_FromtheMelonCollection}
                                imageX2={ChristopherHewat_FromtheMelonCollection}
                                alt="From the Melon Collection"
                                caption="From the Melon Collection"
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-5 offset-lg-6">
                            <ImageBlock
                                author="Donald Barthelme"
                                image={DonaldBarthelme_HenryJamesChief}
                                imageX2={DonaldBarthelme_HenryJamesChief}
                                caption="Henry James, Chiefs"
                                alt="Henry James, Chiefs"
                                signaturePosition={positions.BOTTOM_LEFT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-1">
                            <ImageBlock
                                author="Donald Windham"
                                image={DonaldWindham_Pier}
                                imageX2={DonaldWindham_Pier}
                                alt="Pier. Provincetown, Mass."
                                caption="Pier. Provincetown, Mass."
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 offset-lg-6">
                            <ImageBlock
                                imageCustomClassName="border border-2"
                                author="Claus Hole"
                                image={ClausHole_GenusBos}
                                imageX2={ClausHole_GenusBos}
                                caption="Genue Bos"
                                alt="Big cow painted"
                                signaturePosition={positions.BOTTOM_LEFT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 offset-lg-1">
                            <ImageBlock
                                imageCustomClassName="border border-2"
                                author="Edward Gorey"
                                image={EdwardGorey_Untitled}
                                imageX2={EdwardGorey_Untitled}
                                alt="Postcard, man and woman beetween fyling leafs"
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-5">
                            <ImageBlock
                                author="Gonzalo Fonseca"
                                image={GonzaloFonseca_UrnaParcarum}
                                imageX2={GonzaloFonseca_UrnaParcarum}
                                caption="Urna Parcarum"
                                alt="Urna Parcarum"
                                signaturePosition={positions.BOTTOM_LEFT}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <QuoteSection data={quotes.galleryQuoteII} mt0/>
            <section className="position-relative overflow-hidden">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 offset-lg-1">
                            <ImageBlock
                                author="Helen Miranda Wilson"
                                image={HelenMirandaWilson_July}
                                imageX2={HelenMirandaWilson_July}
                                alt="July"
                                caption="July"
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-5">
                            <ImageBlock
                                author="Hitch Lyman"
                                image={HitchLyman_TheTempleofZeusAthens}
                                imageX2={HitchLyman_TheTempleofZeusAthens}
                                caption="The Temple of Zeus, Athens"
                                alt="The Temple of Zeus, Athens"
                                signaturePosition={positions.BOTTOM_LEFT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-1">
                            <ImageBlock
                                author="James S. Rossant"
                                image={JamesSRossant_TheBrownorWesternGateontheRiverBrue}
                                imageX2={JamesSRossant_TheBrownorWesternGateontheRiverBrue}
                                alt="The Brown or Western Gate on the River Brue"
                                caption="The Brown or Western Gate on the River Brue"
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-5">
                            <ImageBlock
                                imageCustomClassName="border border-2"
                                author="James Stevenson"
                                image={JamesStevensonMorning}
                                imageX2={JamesStevensonMorning}
                                caption="Morning"
                                alt="Morning"
                                signaturePosition={positions.BOTTOM_LEFT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-1">
                            <ImageBlock
                                author="Janet Stayton"
                                image={JanetStayton_GreenCupLaVita}
                                imageX2={JanetStayton_GreenCupLaVita}
                                alt="Green Cup - La Vita"
                                caption="Green Cup - La Vita"
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-5 offset-lg-7">
                            <ImageBlock
                                author="Jim Dine"
                                image={JimDine_SelfPortrait1977}
                                imageX2={JimDine_SelfPortrait1977}
                                caption="Self Portrait, 1977"
                                alt="Self Portrait, 1977"
                                signaturePosition={positions.BOTTOM_LEFT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-1">
                            <ImageBlock
                                imageCustomClassName="border border-2"
                                author="Joe Brainard"
                                image={JoeBrainard_YellowButterfly}
                                imageX2={JoeBrainard_YellowButterfly}
                                alt="Yellow Butterfly"
                                caption="Yellow Butterfly"
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 offset-lg-6">
                            <ImageBlock
                                imageCustomClassName="border border-2"
                                author="John Cage"
                                image={JohnCage_Forty_fiveDrawingsbyThoreau}
                                imageX2={JohnCage_Forty_fiveDrawingsbyThoreau}
                                caption="Forty-five Drawings by Thoreau"
                                alt="Forty-five Drawings by Thoreau"
                                signaturePosition={positions.BOTTOM_LEFT}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <QuoteSection data={quotes.galleryQuoteIII} mt0/>
            <section className="position-relative overflow-hidden">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 offset-lg-1">
                            <ImageBlock
                                imageCustomClassName="border border-2"
                                author="John Morning"
                                image={JohnMorning_FourbySixThreebyEight}
                                imageX2={JohnMorning_FourbySixThreebyEight}
                                alt="Four by Six, Three by Eight"
                                caption="Four by Six, Three by Eight"
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-5">
                            <ImageBlock
                                imageCustomClassName="border border-2"
                                author="Lee Krasner"
                                image={LeeKrasner_WildlifeinSouthwesternDesert}
                                imageX2={LeeKrasner_WildlifeinSouthwesternDesert}
                                caption="Wildlife in Southwestern Desert"
                                alt="Wildlife in Southwestern Desert"
                                signaturePosition={positions.BOTTOM_LEFT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-1">
                            <ImageBlock
                                author="Lenore Tawney"
                                image={LenoreTawney_Untitled}
                                imageX2={LenoreTawney_Untitled}
                                alt="Card with red string"
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-5">
                            <ImageBlock
                                imageCustomClassName="border border-2"
                                author="Marilyn Hamann"
                                image={MarilynHamann_Untitled}
                                imageX2={MarilynHamann_Untitled}
                                alt="Lady with whistle"
                                signaturePosition={positions.BOTTOM_LEFT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-1">
                            <ImageBlock
                                author="Maureen McCabe"
                                image={MaureenMcCabe_ALadyoftheNightLeadingtheBull}
                                imageX2={MaureenMcCabe_ALadyoftheNightLeadingtheBull}
                                caption="A Lady of the Night Leading the Bull"
                                alt="A Lady of the Night Leading the Bull"
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-5 offset-lg-7">
                            <ImageBlock
                                author="Michael Graves"
                                image={MichaelGraves_PreandPostCard}
                                imageX2={MichaelGraves_PreandPostCard}
                                caption="Pre and Post Card"
                                alt="Pre and Post Card"
                                signaturePosition={positions.BOTTOM_LEFT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-1">
                            <ImageBlock
                                thisIsVideo
                                author={`Michael Langenstein`}
                                image={two_in_time_video}
                                imageX2={two_in_time_video}
                                caption="Two in time"
                                alt="Two in time"
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-5 offset-lg-7">
                            <ImageBlock
                                author="Paul Linfante"
                                image={PaulLinfante_Ricordi1977}
                                imageX2={PaulLinfante_Ricordi1977}
                                caption="Ricordi, 1977"
                                alt="Ricordi, 1977"
                                signaturePosition={positions.BOTTOM_LEFT}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <QuoteSection data={quotes.galleryQuoteIV} mt0/>
            <section className="position-relative overflow-hidden">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 offset-lg-1">
                            <ImageBlock
                                author="Richard Benson"
                                image={RichardBenson_TheChateauatMaintenon}
                                imageX2={RichardBenson_TheChateauatMaintenon}
                                alt="The Chateau at Maintenon"
                                caption="The Chateau at Maintenon"
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-5">
                            <ImageBlock
                                author="Robert M. Kulicke"
                                image={RobertMKulicke_StillLifeFruit}
                                imageX2={RobertMKulicke_StillLifeFruit}
                                caption="Still Life, Fruit"
                                alt="Still Life, Fruit"
                                signaturePosition={positions.BOTTOM_LEFT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-1">
                            <ImageBlock
                                author="Robert Morris"
                                image={RobertMorrisPentagon}
                                imageX2={RobertMorrisPentagon}
                                caption="Pentagon"
                                alt="Pentagon"
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-5">
                            <ImageBlock
                                author="Robert Reid"
                                image={RobertReid_Figureonthebeach}
                                imageX2={RobertReid_Figureonthebeach}
                                caption="Figure on the beach #2"
                                alt="Figure on the beach #2"
                                signaturePosition={positions.BOTTOM_LEFT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-1">
                            <ImageBlock
                                author="Saul Steinberg"
                                image={SaulSteinberg_EastSideSunset}
                                imageX2={SaulSteinberg_EastSideSunset}
                                caption="East Side Sunset"
                                alt="East Side Sunset"
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className="gallery-intro-section position-relative overflow-hidden">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <h2 className="text-primary fz-50 lh-1-4">Series II</h2>
                        </div>
                        <div className="col-lg-8">
                            <p className="c-mb-only-5 fz-24 lh-1-5 fw-light">
                                Series II consists of fifty works in varied media, produced originally on more than ten different papers, by diverse printing processes. The artists are painters, sculptors, photographers, poets, cartoonists, writers, filmmakers, architects, and a dancer.
                            </p>
                            <Button link="/gallery/series-II" buttonText="Explore Series II" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="gallery-intro-section position-relative overflow-hidden">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <h2 className="text-primary fz-50 lh-1-4">Holiday Cards</h2>
                        </div>
                        <div className="col-lg-8">
                            <p className="c-mb-only-5 fz-24 lh-1-5 fw-light">
                                Based on the popularity of the Artists’ Postcards Series I and II editions, select artists from the earlier series created special cards for the 1979 holiday season.
                            </p>
                            <Button link="/gallery/holiday-cards" buttonText="Explore Holiday Cards" />
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
